import React, { Component, Fragment } from "react";
import TipService from "../../../services/TipService.js";
import UserService from "../../../services/UserService.js";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import TipLoader from "./TipLoader.js";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneIcon from "@mui/icons-material/Done";
import {
  AWS_URL,
  REDUX_LOCAL_STORE_KEY,
} from "../../../components/config/constants.js";
// import AddNewCardModal from "../../../components/common/commonModal/AddNewCardModal.jsx";
import DisclaimerPaymentFooter from "../web/components/DisclaimerPaymentFooter.js";
import CcbillService from "../../../services/ccbillService.js";
import AddNewCardModalCcbill from "../../../components/common/commonModal/AddNewCardModalCcbill.jsx";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
class TipModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipList: [],
      tipValue: 0,
      doPayment: false,
      customValue: null,
      message: "",
      cardList: [],
      showCards: false,
      // customerId: JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
      //   .authentication.userData.stripeCustomerId,
      selectedCardIndex: 0,
      loading: false,
      taxAmount: 0,
      totalWithTax: 0,
      tokenCcbill: "",
    };
  }

  componentDidMount = () => {
    this.getTipList();
    this.getTokenCcbill();
    // this.getAddedCardList();
    this.getAddedCardListCcbill();
  };

  handleAddCardModal = (val) => {
    this.setState({ isAddCardModalOpen: val });
  };

  // getAddedCardList = () => {
  //   let params = {
  //     customerId: this.state.customerId,
  //   };

  //   UserService.getAddedCardList({ ...params })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         this.setState({ cardList: res?.data?.data }, () => {
  //           // if (this.state.cardList.length > 0) {
  //           //   this.setState({ selectedCardId: res?.data?.data[0].id });
  //           // }
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       this.setState({ cardList: [] });
  //       console.error(err?.response?.message);
  //     });
  // };

  getAddedCardListCcbill = () => {
    CcbillService.getAddedCardList()
      .then((res) => {
        let myCardList = res?.data?.data.map((mySingleCard) => {
          let newSingleCard = mySingleCard;
          newSingleCard.id = mySingleCard.paymentTokenId;
          newSingleCard.brand = mySingleCard.cardName;
          newSingleCard.card = newSingleCard;
          return newSingleCard;
        });
        this.setState({ cardList: myCardList }, () => {
          if (this.state.cardList.length > 0) {
            console.log(this.state.cardList);
            this.setState({ selectedCardId: myCardList[0].id });
          }
        });
        // if (res.status === 200) {
        //   setAddedCardList(res?.data?.data);
        //   setLoading(false);
        // }
        // setLoading(false);
      })
      .catch((err) => {
        console.error(err?.response?.message);
      });
  };

  getTokenCcbill = () => {
    CcbillService.ccbillToken()
      .then((res) => {
        this.setState({ tokenCcbill: res.data.data.access_token });
      })
      .catch((err) => {
        //props?.handleLoading(false);
      });
  };

  addCardCcbill = (myNewCardDetailsToEdit, extraDetails) => {
    this.setState({ loading: true, isAddCardModalOpen: false });
    CcbillService.createPaymentToken(
      myNewCardDetailsToEdit,
      this.state.tokenCcbill
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Errore nella richiesta");
        }
        return res.json();
      })
      .then((data) => {
        this.addCardToCcbill(data, extraDetails);
      })
      .catch((error) => {
        console.log("Errore:", error);
        this.setState({ loading: false, isAddCardModalOpen: false });
      });
  };

  addCardToCcbill = async (myCard, cardDetails) => {
    let editedCardDetails = cardDetails;
    editedCardDetails.paymentTokenId = myCard.paymentTokenId;
    CcbillService.ccbillAddCard(editedCardDetails).then((res) => {
      this.setState({ loading: false, isAddCardModalOpen: false });
      this.getAddedCardListCcbill();
    });
  };

  addedCard = () => {
    // this.getAddedCardList();
    this.getAddedCardListCcbill();
    this.setState({ isAddCardModalOpen: false });
  };

  totalTip = () => {
    this.setState({ loading: true });
    let params = {
      // paymentMethodId: this.state.cardList[this.state.selectedCardIndex]?.id, stripe
      // customAmount: this.state.tipValue * 100,
      userCardId: this.state.cardList[this.state.selectedCardIndex]?._id,
      customAmount: this.state.tipValue * 100,
    };

    TipService.totalTip({ ...params })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            taxAmount: res.data.data.taxAmount / 100,
            totalWithTax: res.data.data.tipAmountIncludedTaxes / 100,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        // toast.error(err.message);
      });
  };

  sendTip = () => {
    this.props.onHide();
    this.setState({ loading: true });
    let params = {
      creatorId: this.props.id,
      message: this.state.message,
      paymentMethodId: this.state.cardList[this.state.selectedCardIndex]?.id,
      customAmount: this.state.tipValue * 100,
      tipArea: this.props.tipArea,
      objId: this.props.objId,
    };

    TipService.sendTip({ ...params })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ loading: false });
          toast.success(this.props.t("tip.tipSuccessfullySend"));
          // this.props.onHide();
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  getTipList = () => {
    this.setState({ loading: true });
    let params = {
      creatorId: this.props.id,
    };
    TipService.tipList({ ...params })
      .then((res) => {
        this.setState({ tipList: res.data.data, loading: false });
      })
      .catch((err) => {
        this.setState({ showModalAddTip: false, loading: false });
        toast.error(err.message);
      });
  };

  handleTipValue = (value) => {
    let sanitizedValue = value.replace(/[^0-9.,]/g, "");
    if (/^[0-9]*\.?[0-9]{0,2}$/.test(sanitizedValue)) {
      if (sanitizedValue <= 1000 && value >= 0) {
        this.setState({
          tipValue: sanitizedValue,
          customValue: sanitizedValue,
        });
      } else {
        if (value === "") {
          this.setState({
            tipValue: 0,
            customValue: 0,
          });
        } else {
          this.setState({
            tipValue: 1000,
            customValue: 1000,
          });
        }
      }
    }
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.show}
          onHide={() => this.props.onHide()}
          keyboard={false}
          centered
          style={this.state.isAddCardModalOpen ? { opacity: "0" } : {}}
        >
          <Modal.Header closeButton className="ps-3 pt-2 pb-2">
            <span className="text-color-dark fw-700 font-xssss">
              {this.props.t("tip.tipCreator")}
            </span>
          </Modal.Header>
          {this.state.loading ? (
            <Modal.Body className="add-card-modal">
              <Loader />
              <TipLoader></TipLoader>
            </Modal.Body>
          ) : (
            <Modal.Body style={{ maxHeight: "70vh", overflowY: "scroll" }}>
              {this.state.doPayment === false ? (
                <>
                  <span className="f-black darktheme-txt wallet-secret-text">
                    {this.props.t("tip.tipAmount")}
                  </span>

                  <div style={{ flexWrap: "wrap" }} className="flex-paginator">
                    {this.state.tipList.map((value, index) => (
                      <Button
                        style={{ maxWidth: "25%" }}
                        onClick={() => this.setState({ tipValue: value.price })}
                        className={`${
                          value.price === this.state.tipValue
                            ? "tip-button-selected"
                            : "tip-button"
                        } tip-margin-rigth ${index} ${
                          index === 3 && "remove-margin-rigth"
                        }`}
                      >
                        ${value.price}
                      </Button>
                    ))}
                    {this.state.tipList.length < 4 && (
                      <Fragment>
                        <span
                          style={{
                            display: "flex",
                            width: "25%",
                            flexWrap: "wrap",
                          }}
                        >
                          <input
                            value={this.state.customValue}
                            style={{ width: "calc(25% - 8px)" }}
                            onClick={(value) =>
                              this.handleTipValue(value.target.value)
                            }
                            onChange={(value) =>
                              this.handleTipValue(value.target.value)
                            }
                            type="number"
                            placeholder={this.props.t("tip.custom")}
                            className={`${
                              this.state.customValue === this.state.tipValue
                                ? "tip-button-selected"
                                : "tip-button"
                            } input-tip`}
                          ></input>
                          <span
                            style={{
                              fontSize: "14px",
                              width: "0px",
                              color: this.state.customValue
                                ? "#C80000"
                                : "#A3A3A3",
                              marginBottom: "17px",
                              right: "92.5%",
                              alignSelf: "center",
                              position: "relative",
                              paddingRight: "2px",
                            }}
                          >
                            $
                          </span>
                          <div
                            style={{
                              textAlignLast: "left",
                              marginTop: "-6px",
                            }}
                            className="text-min-price-chat"
                          >
                            {this.props.t("chat.minPrice3")}
                          </div>
                        </span>
                      </Fragment>
                    )}
                  </div>
                  {this.state.tipList.length === 4 && (
                    <Fragment>
                      <span style={{ display: "flex" }}>
                        <input
                          value={this.state.customValue}
                          style={{ maxWidth: "calc(25% - 8px)" }}
                          onClick={(value) =>
                            this.handleTipValue(value.target.value)
                          }
                          onChange={(value) =>
                            this.handleTipValue(value.target.value)
                          }
                          type="number"
                          placeholder={this.props.t("tip.custom")}
                          className={`${
                            this.state.customValue === this.state.tipValue
                              ? "tip-button-selected"
                              : "tip-button"
                          } input-tip`}
                        ></input>

                        {this.state.customValue && (
                          <span
                            style={{
                              fontSize: "14px",
                              width: "0px",
                              color:
                                this.state.customValue === this.state.tipValue
                                  ? "#C80000"
                                  : "#A3A3A3",
                              marginBottom: "16px",
                              right: "22%",
                              alignSelf: "center",
                              position: "relative",
                              paddingRight: "2px",
                            }}
                          >
                            $
                          </span>
                        )}
                      </span>
                      <div
                        style={{
                          textAlignLast: "left",
                          marginTop: "-6px",
                        }}
                        className="text-min-price-chat"
                      >
                        {this.props.t("chat.minPrice3")}
                      </div>
                    </Fragment>
                  )}
                </>
              ) : (
                <>
                  <p className="f-black darktheme-txt font-xsss">
                    {this.props.t("tip.descriptionModal")}
                  </p>
                  <span
                    style={{ marginBottom: "8px" }}
                    className="card-money-choose f-black darktheme-txt "
                  >
                    {this.props.t("tip.tipAmount")}
                  </span>
                  <div className="card-money-available f-black darktheme-txt  mb-16px">
                    ${this.state.tipValue}
                  </div>
                  {this.state.taxAmount > 0 && (
                    <span
                      style={{ marginBottom: "8px" }}
                      className="card-money-choose f-black darktheme-txt "
                    >
                      {this.props.t("payment.tax")}
                    </span>
                  )}
                  {this.state.taxAmount > 0 && (
                    <div className="card-money-available f-black darktheme-txt  mb-16px">
                      ${this.state.taxAmount}
                    </div>
                  )}
                  <span
                    style={{ marginBottom: "8px" }}
                    className="card-money-choose f-black darktheme-txt "
                  >
                    {this.props.t("common.total")}
                  </span>
                  <div className="card-money-available f-black darktheme-txt  mb-16px">
                    ${this.state.totalWithTax}
                  </div>
                </>
              )}

              {this.state.doPayment === false ? (
                <>
                  <span
                    style={{ marginBottom: "8px" }}
                    className="wallet-secret-text f-black darktheme-txt "
                  >
                    {this.props.t("tip.optionalMessage")}
                  </span>

                  <div className="text-area-tip mb-16px">
                    <textarea
                      maxLength={150}
                      placeholder={this.props.t("tip.tipMessage")}
                      className="fix-text-area-dark-tip post-input no-resize"
                      onChange={(value) =>
                        this.setState({ message: value.target.value })
                      }
                    ></textarea>
                  </div>
                </>
              ) : (
                <>
                  <span className="card-money-choose f-black darktheme-txt ">
                    {this.props.t("tip.optionalMessage")}
                  </span>
                  <textarea
                    value={this.state.message}
                    disabled={true}
                    style={{ backgroundColor: "transparent" }}
                    placeholder={this.props.t("tip.noMessage")}
                    className={`post-input no-resize card-money-available ${
                      this.state.message.length < 50 && "height24px"
                    }`}
                    onChange={(value) =>
                      this.setState({ message: value.target.value })
                    }
                  ></textarea>
                </>
              )}
              <div className="obliterate-padding-footer-modal">
                {this.state.doPayment === true && (
                  <Modal.Footer className="payment-card">
                    <div className="text-payment-method-tip f-black darktheme-txt ">
                      {this.props.t("tip.paymentMethod")}
                    </div>
                    {/* {this.state.cardList.length > 0 ? (
                      <select
                        onChange={(value) =>
                          this.setState(
                            { selectedCardId: value.target.value },
                            () => this.totalTip()
                          )
                        }
                        value={this.state.selectedCardId}
                        placeholder={this.props.t("tip.selectPaymentMethod")}
                        className="w-100 border-select-accout select-tip"
                      >
                        {this.state.cardList.map((value, index) => (
                          <option value={value.id}>
                            {value.card.brand} **** {value.card.last4}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span className="card-money-choose">
                        {this.props.t("tip.noCard")}
                      </span>
                    )}Vecchio metodo di visualizzazione delle carte che non permette l'aggiunta */}
                    <div
                      style={{ width: "100%" }}
                      className="fix-style-payment-chat"
                    >
                      <div className="payment-page">
                        <div className="payment-card" style={{ border: "0px" }}>
                          <form action="#">
                            <div
                              className={`mb-3 ${
                                this.state.cardList.length !== 0
                                  ? "payment-option cards"
                                  : ""
                              }`}
                            >
                              {this.state.cardList.length !== 0 ? (
                                <div className="checkout-option">
                                  <input
                                    type="radio"
                                    className="cursor-pointer"
                                    id="Black"
                                    name="payment-method"
                                    checked={true}
                                  />
                                  <label class="ms-3 payment-details w-100 d-flex justify-content-between align-items-center">
                                    <div className="card-detail">
                                      <div className="card-logo">
                                        <img
                                          src={`${AWS_URL}logo/${
                                            this.state.cardList[
                                              this.state.selectedCardIndex
                                            ]?.card?.logoImageName
                                          }`}
                                          alt="card-logo"
                                          height={44}
                                        />
                                      </div>
                                      <div className="card-number">
                                        <span className="subtitle text-color-dark">
                                          ****{" "}
                                          {
                                            this.state.cardList[
                                              this.state.selectedCardIndex
                                            ]?.card?.last4
                                          }
                                        </span>
                                        <span className="details details-text fw-400 text-xssss">
                                          {this.props.t("payment.expiresOn")}{" "}
                                          {/* {this.state.cardList[
                                            this.state.selectedCardIndex
                                          ]?.card?.exp_month +
                                            "/" +
                                            this.state.cardList[
                                              this.state.selectedCardIndex
                                            ]?.card?.exp_year
                                              .toString()
                                              .slice(-2)} Stripe */}
                                          {this.state.cardList[
                                            this.state.selectedCardIndex
                                          ]?.card?.expMonth +
                                            "/" +
                                            this.state.cardList[
                                              this.state.selectedCardIndex
                                            ]?.card?.expYear
                                              .toString()
                                              .slice(-2)}
                                        </span>
                                      </div>
                                    </div>
                                    <KeyboardArrowDownIcon
                                      onClick={() => {
                                        this.setState({
                                          showCards: !this.state.showCards,
                                        });
                                      }}
                                    />
                                  </label>
                                </div>
                              ) : null}
                              {this.state.showCards && (
                                <div className="all-cards-dropdown overflow-y-scroll">
                                  {this.state.cardList.map((card, index) => (
                                    <div
                                      className={`card-datails-container pointer d-flex justify-content-between align-items-center ${
                                        this.state.selectedCardIndex === index
                                          ? "selected"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        this.setState({ showCards: false });
                                        this.setState(
                                          {
                                            selectedCardIndex: index,
                                          },
                                          () => {
                                            this.totalTip();
                                          }
                                        );
                                      }}
                                    >
                                      <div className="card-detail">
                                        <div className="card-logo">
                                          <img
                                            src={`${AWS_URL}logo/${card?.card?.logoImageName}`}
                                            alt="card-logo"
                                            height={44}
                                          />
                                        </div>
                                        <div className="card-number">
                                          <span className="subtitle text-color-dark fw-bold text-xsss">
                                            {card.card.brand +
                                              "****" +
                                              card.card.last4}
                                          </span>
                                          <span className="details  details-text fw-400 text-xssss">
                                            {this.props.t("payment.expiresOn")}{" "}
                                            {/* {card.card.exp_month +
                                              "/" +
                                              card.card.exp_year
                                                .toString()
                                                .slice(-2)} Stripe */}
                                            {card.card.expMonth +
                                              "/" +
                                              card.card.expYear
                                                .toString()
                                                .slice(-2)}
                                          </span>
                                        </div>
                                      </div>
                                      {this.state.selectedCardIndex ===
                                      index ? (
                                        <DoneIcon className="selected-icon text-white bg-red rounded-pill p-1" />
                                      ) : null}
                                    </div>
                                  ))}

                                  <div
                                    onClick={() => {
                                      this.setState({ showCards: false });
                                      this.handleAddCardModal(true);
                                    }}
                                    class="card-datails-container pointer payment-details"
                                  >
                                    + {this.props.t("payment.addNewCard")}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="mb-1 payment-option">
                              <div
                                className="checkout-option"
                                onClick={() => {
                                  this.setState({ isAddCardModalOpen: true });
                                }}
                              >
                                <label class="ms-3 payment-details darktheme-txt">
                                  + {this.props.t("payment.addNewCard")}
                                </label>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Modal.Footer>
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <Modal.Footer className="d-flex p-2 justify-content-end">
                    <div
                      className="pointer fw-bold me-4 font-xsss text-silver"
                      onClick={() => this.props.onHide()}
                    >
                      {this.props.t("common.cancel")}
                    </div>

                    {this.state.doPayment === false ? (
                      <Button
                        className="fix-tip-button fw-bold me-2 rounded-pill font-xssss text-white px-3 py-2 pointer"
                        onClick={() =>
                          this.state.tipValue >= 3
                            ? this.setState({ doPayment: true }, () =>
                                this.totalTip()
                              )
                            : toast.error(this.props.t("tip.min1"))
                        }
                      >
                        {this.props.t("tip.sendWithTip")}
                      </Button>
                    ) : (
                      <Button
                        className="fix-tip-button fw-bold me-2 rounded-pill font-xssss text-white px-3 py-2 pointer"
                        onClick={() =>
                          this.state.cardList[this.state.selectedCardIndex]?.id
                            ? this.sendTip()
                            : toast.error("tip.noCard")
                        }
                        disabled={
                          this.state.cardList[this.state.selectedCardIndex]?.id
                            ? false
                            : true
                        }
                      >
                        {this.props.t("tip.sendWithTip")}
                      </Button>
                    )}

                    {/* segnaposto fine carte */}
                    <div className="disclaimer darktheme-txt mb-2">
                      {this.props.t("tip.disclaimer", {
                        userName: this.props.userName,
                      })}
                    </div>
                    <DisclaimerPaymentFooter></DisclaimerPaymentFooter>
                  </Modal.Footer>
                </div>
              </div>
            </Modal.Body>
          )}
        </Modal>
        {/* <Elements stripe={stripePromise}>
          <AddNewCardModal
            onSubmit={() => {
              this.addedCard();
            }}
            show={this.state.isAddCardModalOpen}
            handleModal={() => {
              this.handleAddCardModal();
            }}
            subscribedUserId={
              JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
                .authentication.userData._id
            }
            propsT={this.props.t}
          />
        </Elements> */}
        <AddNewCardModalCcbill
          onSubmit={(myNewCardDetails, extraDetails) => {
            //this.addedCard();
            this.addCardCcbill(myNewCardDetails, extraDetails);
          }}
          show={this.state.isAddCardModalOpen}
          handleModal={() => {
            this.handleAddCardModal();
          }}
          subscribedUserId={
            JSON.parse(localStorage.getItem(REDUX_LOCAL_STORE_KEY))
              .authentication.userData._id
          }
          propsT={this.props.t}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(TipModal);
