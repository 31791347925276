import RequestService from "./RequestService";

const isDev = process.env.REACT_APP_ENV === "development";

const CcbillService = {
  ccbillToken: function (params) {
    return RequestService.get(`/ccbill/oauth`, params);
  },
  ccbillAddCard: function (params) {
    return RequestService.post(`/ccbill/add-card`, params);
  },
  getAddedCardList: function () {
    return RequestService.get(`/ccbill/card-list`);
  },
  createPaymentToken: function (params, token) {
    return RequestService.postCcbill(
      `/payment-tokens/merchant-only-verify${
        (isDev ? "?body=" + JSON.stringify(params) : "") +
        (isDev ? "&token=" + token : "")
      }`,
      params,
      {
        Authorization: "Bearer " + token,
      }
    );
  },
};

export default CcbillService;

// process.env.REACT_APP_ENV
